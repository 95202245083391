import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';

import { CoreService } from 'src/app/shared/services/core.service';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent implements OnInit {
  public sidebarVisible = false;
  
  @ViewChild('contentWrapper') contentWrapper: ElementRef; // Corrected selector
  
  constructor(private coreService: CoreService, private renderer: Renderer2) { }

  async ngOnInit() {
    this.sidebarVisible = await this.coreService.isCurrentUserActive();
  }

  pushContent() {
    this.renderer.setStyle(this.contentWrapper.nativeElement, 'margin-left', '350px');
    this.renderer.setStyle(this.contentWrapper.nativeElement, 'overflow', 'hidden');
  }

  pullContent() {
    this.renderer.setStyle(this.contentWrapper.nativeElement, 'margin-left', '0px');
    this.renderer.setStyle(this.contentWrapper.nativeElement, 'overflow', 'visible');
  }
}
